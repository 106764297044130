var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"irbVjCMyaRGc9ChNt7qM3"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

// The Sentry wizard will automatically patch your application to configure the Sentry SDK:
//   Create sentry.client.config.js and sentry.server.config.js with the default Sentry.init.
//   Create or update your Next.js config next.confg.js with the default Sentry configuration
// Create .sentryclirc and sentry.properties files with configuration for sentry-cli (which is used when automatically uploading source maps).
// add an example page to your app to verify your Sentry setup
import * as Sentry from '@sentry/nextjs';
import { sentryConfig } from './get-sentry-token';

const isLocal = process.env.NEXT_PUBLIC_MOON_ENV === 'local';

Sentry.init({
  enabled: !!sentryConfig?.dsn,
  dsn: sentryConfig?.dsn,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    new Sentry.Replay({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});
